<template>
  <div class="listCreneaux" :data-clientSelect="clientSelect">

    <div v-for="(n, k) in days" :key="k" class="listMain">

      <h4>{{ n.title }}</h4>
      <div class="list">

        <div class="elem" :data-full="creneauxApi && creneauxApi[n.day] && creneauxApi[n.day][c.hour] && c.nb - creneauxApi[n.day][c.hour]['nb'] <= 0" :data-start="creneauxApi && creneauxApi[n.day] && creneauxApi[n.day][c.hour] && c.nb - creneauxApi[n.day][c.hour]['nb'] > 0"
             v-for="(c, k2) in n.creneaux" :key="k2" @click="clickCreneau(n, c)">




          <div class="top">
            <div class="left">
              <div class="hour">
                {{ formatAt(c.hour) }}
              </div>
              <div class="t">
                <!--              <div class="title">{{ c.hour }}h</div>-->
                <div class="pres" v-if="creneauxApi && creneauxApi[n.day] && creneauxApi[n.day][c.hour] && creneauxApi[n.day][c.hour]['nb']">
                  <template v-if="c.nb - creneauxApi[n.day][c.hour]['nb'] > 0">{{ c.nb - creneauxApi[n.day][c.hour]['nb'] }}</template>
                  <template v-else>0</template>
                  
                  place<template v-if="c.nb - creneauxApi[n.day][c.hour]['nb'] !== 1">s</template> garantie<template v-if="c.nb - creneauxApi[n.day][c.hour]['nb'] !== 1">s</template>
                </div>
                <div class="pres" v-else>{{ c.nb }} places garanties</div>
                
                <div class="pres" v-if="creneauxApi && creneauxApi[n.day] && creneauxApi[n.day][c.hour] && creneauxApi[n.day][c.hour]['nb']">{{ creneauxApi[n.day][c.hour]['nb'] }} inscrit<template v-if="creneauxApi[n.day][c.hour]['nb'] !== 1">s</template></div>
                
              </div>
            </div>
            <div class="right">
              <div class="btn" v-if="clientSelect && (!creneauxApi || !creneauxApi[n.day] || !creneauxApi[n.day][c.hour] || c.nb - creneauxApi[n.day][c.hour]['nb'] > 0)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18.536 7.555c-1.188-.252-4.606-.904-5.536-1.088v-3.512c0-1.629-1.346-2.955-3-2.955s-3 1.326-3 2.955v7.457c-.554-.336-1.188-.621-1.838-.715-1.822-.262-3.162.94-3.162 2.498 0 .805.363 1.613 1.022 2.271 3.972 3.972 5.688 5.125 6.059 9.534h9.919v-1.748c0-5.154 3-6.031 3-10.029 0-2.448-1.061-4.157-3.464-4.668zm.357 8.022c-.821 1.483-1.838 3.319-1.891 6.423h-6.13c-.726-3.82-3.81-6.318-6.436-8.949-.688-.686-.393-1.37.442-1.373 1.263-.006 3.06 1.884 4.122 3.205v-11.928c0-.517.458-.955 1-.955s1 .438 1 .955v6.948c0 .315.256.571.572.571.314 0 .57-.256.57-.571v-.575c0-.534.49-.938 1.014-.833.398.079.686.428.686.833v1.273c0 .315.256.571.571.571s.571-.256.571-.571v-.83c0-.531.487-.932 1.008-.828.396.078.682.424.682.828v1.533c0 .315.256.571.571.571s.571-.256.571-.571v-.912c0-.523.545-.867 1.018-.646.645.305 1.166.932 1.166 2.477 0 1.355-.465 2.193-1.107 3.354z"/></svg>
              </div>
            </div>
          </div>






          <div class="bottom" v-if="creneauxApi && creneauxApi[n.day] && creneauxApi[n.day][c.hour]">
            <div class="user" v-for="(nUser, k) in creneauxApi[n.day][c.hour]['data']" :key="k" :data-sepgarantie="k === c.nb" :data-present="nUser.present">
              <div class="l">
                
                <div class="key" v-if="!nUser.present">{{ k+1 }}</div>
                <div class="key" v-else>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z"/></svg>
                </div>
                
                <div class="nameUser">
                  {{ nUser.user_data['SEX'] }} {{ nUser.user_data['NAME'] }} {{ nUser.user_data['FIRSTNAME'].split(' ')[0] }}
                  <div v-if="nUser.client_id" class="badge">Players+</div>
                </div>
                
<!--                {{ $dayjs().add(8, 'hours').format('HH:mm') }}-->
<!--                {{ $dayjs().add(2, 'hours').format('HH:mm') }}-->
              </div>
              <div class="r">
                <div class="phone" v-if="nUser.user_data['TEL'] && $phoneNumber(nUser.user_data['TEL'])">{{ $phoneNumber(nUser.user_data['TEL']).formatNational() }}</div>
              </div>
<!--          {{$dayjs(n.day + ' ' + c.hour, 'DD/MM/YYYY HH:mm')}}-->
              <div class="nav">
                
                <template v-if="$dayjs(n.day + ' ' + c.hour, 'DD/MM/YYYY HH:mm').isBefore($dayjs().add(8, 'hours')) && $dayjs(n.day + ' ' + c.hour, 'DD/MM/YYYY HH:mm').isBefore($dayjs().add(1, 'hours'))">
                <div v-if="!nUser.present" class="btnIco" @click="userPresent(nUser.id)" title="Client présent">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="28" height="28" viewBox="0 0 24 24"><path d="M21.1,12.5L22.5,13.91L15.97,20.5L12.5,17L13.9,15.59L15.97,17.67L21.1,12.5M10,17L13,20H3V18C3,15.79 6.58,14 11,14L12.89,14.11L10,17M11,4A4,4 0 0,1 15,8A4,4 0 0,1 11,12A4,4 0 0,1 7,8A4,4 0 0,1 11,4Z" /></svg>
                </div>
                <div v-else class="btnIco" @click="userNotPresent(nUser.id)" title="Client non présent">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="28" height="28" viewBox="0 0 24 24"><path d="M10 4A4 4 0 0 0 6 8A4 4 0 0 0 10 12A4 4 0 0 0 14 8A4 4 0 0 0 10 4M17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13M10 14C5.58 14 2 15.79 2 18V20H11.5A6.5 6.5 0 0 1 11 17.5A6.5 6.5 0 0 1 11.95 14.14C11.32 14.06 10.68 14 10 14M17.5 14.5C19.16 14.5 20.5 15.84 20.5 17.5C20.5 18.06 20.35 18.58 20.08 19L16 14.92C16.42 14.65 16.94 14.5 17.5 14.5M14.92 16L19 20.08C18.58 20.35 18.06 20.5 17.5 20.5C15.84 20.5 14.5 19.16 14.5 17.5C14.5 16.94 14.65 16.42 14.92 16Z" /></svg>
                </div>
                </template>
                
                <div class="btnIco" @click="unBook(nUser.id)" title="Supprimer la réservation">
                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M5.662 23l-5.369-5.365c-.195-.195-.293-.45-.293-.707 0-.256.098-.512.293-.707l14.929-14.928c.195-.194.451-.293.707-.293.255 0 .512.099.707.293l7.071 7.073c.196.195.293.451.293.708 0 .256-.097.511-.293.707l-11.216 11.219h5.514v2h-12.343zm3.657-2l-5.486-5.486-1.419 1.414 4.076 4.072h2.829zm.456-11.429l-4.528 4.528 5.658 5.659 4.527-4.53-5.657-5.657z"/></svg>
                </div>
              </div>

            </div>
          </div>






        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
// moment.locale('fr')
export default {
  name: "listCreneaux",
  data() {
    return {
      days: [],
      // creneauxApi: {"22/06/2021":{"20:00":1}}
    }
  },
  props: [ 'creneaux', 'creneauxApi', 'clientSelect' ],
  created() {
    let dayBase = this.$dayjs().subtract(1, 'days')
    for(let i = 0; i < 7; i++) {
      let day = dayBase.add(i, 'days')
      
      if( (day.isBefore(this.$dayjs()) && day.isAfter(this.$dayjs().subtract(2, 'days')) && this.$dayjs().format('H') < 6) || day.isAfter(this.$dayjs().subtract(1, 'days')) ){
          this.days.push({
            title: day.format('dddd DD MMMM'),
            day: day.format('DD/MM/YYYY'),
            creneaux: this.creneaux
          })
      }
    }
  },
  methods: {
    formatAt(at) {
      at = at.split(':')
      if(at[1] === '00') return at[0] + 'h'
      else return at[0] + 'h' + at[1]
    },
    clickCreneau(day, hour) {
      // if((this.creneauxApi && this.creneauxApi[day.day] && this.creneauxApi[day.day][hour.hour] && hour.nb - this.creneauxApi[day.day][hour.hour]['nb'] > 0) || (!this.creneauxApi || !this.creneauxApi[day.day] || !this.creneauxApi[day.day][hour.hour]))
        this.$emit('clickCreneau', { creneau: day.day + ' ' + hour.hour + ':00', nb: hour.nb })
    },
    unBook(id) {
      if(confirm('Voulez-vous vraiment supprimer cette réservation ?')) {
        this.$httpStatum.post('/poker/bo/unbook', {
          id: id
        }).then(() => this.$emit('sync') )
      }
    },
    userPresent(id) {
      this.$httpStatum.post('/poker/bo/userpresent', {
        id: id
      }).then(() => this.$emit('sync') )
    },
    userNotPresent(id) {
      this.$httpStatum.post('/poker/bo/usernotpresent', {
        id: id
      }).then(() => this.$emit('sync') )
    }
  }
}
</script>

<style scoped>
h4 { text-transform: capitalize; padding-left: 0; font-size: 26px; margin-top: 50px; }
.listMain:first-child h4 { margin-top: 20.1px; }


.list { }
.list .elem { border-bottom: 2px solid #f1f2fb; border-radius: 6px; margin-bottom: 12px; overflow: hidden; }
.list .elem .top { padding: 14px 18px; display: flex; background: #75b80d; color: #fff; align-items: center; justify-content: space-between; } /*1fbfd4*/
.list .elem .left { display: flex; align-items: center }
.list .elem .hour { width: 60px; height: 60px; margin-right: 20px; display: flex; align-items: center; justify-content: center; font-weight: 1000; font-size: 28px }
/*.list .elem .img img { height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%) }*/
.list .elem .t { display: flex; align-items: center }
.list .elem .t .title { font-size: 17px; font-weight: 500; margin-bottom: 4px; }
.list .elem .t .pres { font-size: 14px; color: #fff; fill: #fff; display: flex; align-items: center; background: rgba(20,20,20,.2); padding: 6px 8px; border-radius: 6px; margin-right: 8px; }
.list .elem .t .pres svg { margin-right: 4px; }
.list .elem .right { font-size: 14px; font-weight: 400; }
.list .elem .right .btn { background: none; border: 1px solid rgba(255,255,255,.6); fill: #555; background: #fff; border-radius: 100%; padding: 10px }
.list .elem[data-active] { }
.list .elem[data-active] .btn { }
.list .elem[data-active] .btn svg { position: relative; top: 1px }
.list .elem[data-active] .t { }
.list .elem[data-empty] { opacity: .4; }

.list .elem .bottom { border-left: 2px solid #f1f2fb; border-right: 2px solid #f1f2fb; border-radius: 0 0 6px 6px; display: flex; flex-direction: column }
.list .elem .bottom .user { height: 50px; display: flex; align-items: center; padding: 0 14px; justify-content: space-between; border-bottom: 1px solid #f1f2fb; position: relative; }
.list .elem .bottom .user:nth-child(even) { background: #fcfcfc }
.list .elem .bottom .user:last-child { border-bottom: none; }
.list .elem .bottom .user .l { display: flex; align-items: center; }
.list .elem .bottom .user .l .key { width: 30px; height: 30px; margin-right: 16px; background: #eff1f3; color: #818283; font-weight: 600; display: flex; align-items: center; justify-content: center; border-radius: 8px }
.list .elem .bottom .user .l .nameUser { display: flex; align-items: center; }
.list .elem .bottom .user .l .nameUser .badge { font-weight: 400; font-size: 14px; color: #ee9724; background: #fcf7f7; margin-left: 8px; padding: 3px 8px; border-radius: 8px }
.list .elem .bottom .user .r { transition: all .03s ease }

.list .elem .nav { position: absolute; right: 0; bottom: 0; top: 0; display: flex; justify-content: flex-end; right: 10px; opacity: 0; transition: right .03s ease }
.list .elem .nav .btnIco { width: 50px; height: 49px; display: flex; align-items: center; justify-content: center; border-left: 1px solid #f1f2fb; background: #fff }
.list .elem .bottom .user:last-child .btnIco { height: 50px; }
.list .elem .nav .btnIco:hover { background: #d7d8e5; cursor: pointer }


.list .elem .bottom .user[data-present] { background: #f1f8e6; color: #75b80d; border-bottom-color: #e4e6f3 }
.list .elem .bottom .user[data-present] .l .key { background: #75b80d; fill: #fff }

.list .elem .bottom .user[data-sepgarantie]:before { content: 'Places non garanties'; position: absolute; top: 0; left: 0; right: 0; height: 40px; display: flex; align-items: center; border-bottom: 1px solid #f1f2fb; padding: 0 16px; background: #eff1f3; color: #444 }
.list .elem .bottom .user[data-sepgarantie] { padding-top: 40px; height: 90px; position: relative; }
.list .elem .bottom .user[data-sepgarantie] .nav { top: 41px }

.listCreneaux:not([data-clientSelect]) .list .elem .bottom .user:hover .nav { right: 0; opacity: 1; }
.listCreneaux:not([data-clientSelect]) .list .elem .bottom .user:hover .r { opacity: 0; transform: translateX(10px) }
.listCreneaux:not([data-clientSelect]) .list .elem .bottom .user:not([data-present]):hover { background: #f1f2fb; }
.listCreneaux:not([data-clientSelect]) .list .elem .bottom .user:not([data-present]):hover .l .key { background: #fff; }

.listCreneaux[data-clientSelect] .list .elem { cursor: pointer }
/*.listCreneaux[data-clientSelect] .list .elem .bottom { display: none }*/

/*.list .elem[data-full] .top { background: #c95f39; cursor: not-allowed }*/


.list .elem[data-start] .top { background: #75b80d; }
/*.list .elem[data-start] { border-color: #2461f3 }*/
/*.list .elem[data-start] .bottom { border-color: #2461f3 }*/

.list .elem[data-full] .top { background: #c95f39; }
/*.list .elem[data-full] { border-color: #c95f39 }*/
/*.list .elem[data-full] .bottom { border-color: #c95f39;  }*/



</style>